<template>
  <div
    class="payment-invoice flex-column"
    v-if="billingData && billingData.person"
  >
    <h5 class="font-weight-semibold">{{ $t("paymentInvoice.title") }}</h5>
    <v-switch
      v-model="needInvoice"
      inset
      :label="$t('paymentInvoice.wantInvoice')"
      @change="handleInvoiceChange"
    ></v-switch>
    <div v-if="needInvoice">
      <v-row v-if="addNewPersonFiscal">
        <v-col>
          <v-card elevation="0" outlined class="rounded-md pa-4">
            <p class="text-center font-weight-semibold">
              {{ $t("paymentInvoice.hasAnIdentifier.title") }}
            </p>
            <div class="w-100 d-flex align-center justify-center">
              <v-btn
                class="rounded-pill ma-1"
                active-class="primary"
                :class="{ 'primary white--text': hasIdentifier === true }"
                color="primary"
                depressed
                outlined
                @click="
                  () => {
                    hasIdentifier = true;
                  }
                "
              >
                {{ $t("paymentInvoice.hasAnIdentifier.yes") }}
              </v-btn>
              <v-btn
                class="rounded-pill ma-1"
                active-class="primary"
                :class="{ 'primary white--text': hasIdentifier === false }"
                color="primary"
                depressed
                outlined
                @click="
                  () => {
                    hasIdentifier = false;
                  }
                "
              >
                {{ $t("paymentInvoice.hasAnIdentifier.no") }}
              </v-btn>
            </div>
            <!-- <small class="mt-3 d-block text-center mx-auto">{{
              $t("paymentInvoice.hasAnIdentifier.help")
            }}</small> -->

            <div v-if="hasIdentifier">
              <v-divider class="my-4"></v-divider>
              <v-row class="mt-1">
                <v-col cols="12">
                  <v-radio-group
                    v-model="billingData.person.personTypeId"
                    mandatory
                    row
                    @change="validate"
                  >
                    <v-radio
                      class="my-1"
                      label="PERSONA FISICA"
                      :value="1"
                    ></v-radio>
                    <v-radio class="my-1" label="SOCIETA'" :value="2"></v-radio>
                    <v-radio
                      class="my-1"
                      label="DITTA INDIVIDUALE"
                      :value="3"
                    ></v-radio>
                    <v-radio
                      class="my-1"
                      label="ENTE PUBBLICO"
                      :value="4"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row class="mt-1 align-start justify-start">
                <v-col cols="6">
                  <v-text-field
                    class="rounded-pill"
                    v-model="identifier"
                    :label="
                      $t('paymentInvoice.hasAnIdentifier.identifierLabel')
                    "
                    outlined
                    dense
                    required
                    :rules="[requiredValue('Campo obbligatorio')]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="rounded-pill"
                    v-model="vatCode"
                    :label="$t('paymentInvoice.hasAnIdentifier.vatcodeLabel')"
                    outlined
                    dense
                    required
                    :rules="cfIvaRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    large
                    class="rounded-pill ma-1"
                    color="primary"
                    depressed
                    style="top: -5px;"
                    @click="anagDataGet()"
                    :disabled="!identifier || !vatCode"
                  >
                    {{ $t("paymentInvoice.hasAnIdentifier.check") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="d-flex justify-space-between"
        v-if="!addNewPersonFiscal && fiscalPersons.length"
      >
        <v-col cols="12">
          <v-select
            v-model="fiscalPerson"
            :placeholder="$t('paymentInvoice.selectAddress')"
            :items="fiscalPersons"
            item-text="text"
            item-value="value"
            hide-details
            single-line
            dense
            outlined
            clearable
            @change="setFiscalPerson"
            class="rounded-pill"
          ></v-select>
        </v-col>
        <!-- <v-col cols="2">
          <v-btn
            x-small
            class="delete-person-fiscal-btn w-100 d-block rounded-pill"
            color="secondary"
            height="40"
            depressed
            :disabled="!fiscalPerson"
            @click="deletePersonFiscal"
          >
            <v-icon size="20" class="mr-1">$trashXmark</v-icon>
            {{ $t("paymentInvoice.deleteAddressBtn") }}
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-0 mb-1">
          <v-btn
            v-if="!addNewPersonFiscal"
            class="add-person-fiscal-btn rounded-pill mr-2"
            color="primary"
            height="40"
            depressed
            @click="addNewAddress"
          >
            <v-icon size="20" class="mr-1">$plus</v-icon>
            {{ $t("paymentInvoice.addAddressBtn") }}
          </v-btn>
          <v-btn
            v-if="addNewPersonFiscal"
            type="button"
            large
            outlined
            color="primary"
            depressed
            class="mr-2 rounded-pill"
            @click.prevent="clear()"
          >
            {{ $t("paymentInvoice.goBack") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-card
        class="payment-invoice-card px-3 mb-3"
        v-if="needInvoice && (hasIdentifier === false || personFiscalFound)"
        :loading="loading"
        elevation="0"
      >
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
        </template>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-row v-if="hasIdentifier === false">
            <v-col cols="12 " class="pb-0">
              <v-radio-group
                v-model="billingData.person.personTypeId"
                mandatory
                row
                @change="validate"
              >
                <v-radio
                  class="my-1"
                  label="PERSONA FISICA"
                  :value="1"
                ></v-radio>
                <v-radio class="my-1" label="SOCIETA'" :value="2"></v-radio>
                <v-radio
                  class="my-1"
                  label="DITTA INDIVIDUALE"
                  :value="3"
                ></v-radio>
                <v-radio
                  class="my-1"
                  label="ENTE PUBBLICO"
                  :value="4"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <template v-if="billingData.person.personTypeId == 1">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.person.firstName"
                  label="Nome *"
                  outlined
                  dense
                  required
                  :rules="[requiredValue('Campo obbligatorio')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.person.lastName"
                  label="Cognome *"
                  outlined
                  dense
                  required
                  :rules="[requiredValue('Campo obbligatorio')]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.person.fiscalCode"
                  label="Codice Fiscale *"
                  :rules="cfRules"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.addressName"
                  :rules="[requiredValue('Nominativo indirizzo fatturazione')]"
                  label="Nominativo indirizzo fatturazione *"
                  required
                  outlined
                  dense
                />
              </v-col>
              <!--<v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.emailCertified"
                label="@PEC"
                :rules="emailRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
             <v-col cols="12"
              >Per modificare questi dati vai alla sezione
              <router-link :to="{ name: 'User' }"> profilo </router-link>
            </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.address1"
                  :rules="[requiredValue('Indirizzo obbligatorio')]"
                  label="Indirizzo *"
                  required
                  outlined
                  dense
                />
              </v-col>

              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.addressNumber"
                  label="Civico *"
                  :rules="[requiredValue('Civico')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.postalcode"
                  label="Cap *"
                  :rules="[requiredValue('Cap obbligatorio')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.city"
                  label="Comune *"
                  :rules="[requiredValue('Comune')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.province"
                  :rules="[requiredValue('Provincia obbligatoria')]"
                  label="Provincia *"
                  required
                  outlined
                  dense
                  maxlength="2"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.email"
                label="Email *"
                :rules="emailPersonRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col> -->
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.person.company"
                  label="Ragione sociale *"
                  :rules="[requiredValue('Ragione sociale')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-if="billingData.person.personTypeId == 3"
                  v-model="billingData.person.vatFiscalCode"
                  label="Codice fiscale *"
                  :rules="cfRules"
                  required
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-if="
                    billingData.person.personTypeId == 2 ||
                      billingData.person.personTypeId == 4
                  "
                  ref="vatFiscalCode"
                  class="rounded-pill"
                  v-model="billingData.person.vatFiscalCode"
                  label="Codice fiscale azienda*"
                  outlined
                  dense
                  required
                  :rules="cfIvaRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.person.vatCode"
                  label="Partita IVA *"
                  :rules="cfIvaRules"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.addressName"
                  :rules="[requiredValue('Nominativo indirizzo fatturazione')]"
                  label="Nominativo indirizzo fatturazione *"
                  required
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.address1"
                  :rules="[requiredValue('Indirizzo obbligatorio')]"
                  label="Indirizzo *"
                  required
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.addressNumber"
                  label="Civico *"
                  :rules="[requiredValue('Civico')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.postalcode"
                  label="Cap *"
                  :rules="[requiredValue('Cap obbligatorio')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.email"
                label="Email *"
                :rules="emailPersonRules"
                required
                outlined
                dense
              ></v-text-field>
            </v-col> -->
            </v-row>
            <v-row>
              <!-- <v-col
              cols="12"
              class="pb-0"
              v-if="billingData.person.personTypeId == 4"
            >
              <v-switch
                v-model="billingData.person.splitPayment"
                inset
                label="Split Payment"
              ></v-switch>
            </v-col> -->
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.city"
                  label="Comune *"
                  :rules="[requiredValue('Comune')]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-text-field
                  class="rounded-pill"
                  v-model="billingData.billingAddress.province"
                  :rules="[requiredValue('Provincia obbligatoria')]"
                  label="Provincia *"
                  required
                  outlined
                  dense
                  maxlength="2"
                />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <!-- <v-text-field
                v-model="billingData.billingAddress.country.name"
                label="Nazione *"
                :rules="[requiredValue('Nazione')]"
                required
                outlined
                dense
              ></v-text-field> -->
                <v-select
                  class="rounded-pill"
                  v-model="billingData.billingAddress.country.countryId"
                  label="Nazione *"
                  item-text="name"
                  item-value="countryId"
                  placeholder="Nazione *"
                  outlined
                  dense
                  elevation="0"
                  :items="customCountries"
                  required
                />
              </v-col>
              <!-- <v-col cols="12" sm="6" class="pb-0">
              <v-text-field
                v-model="billingData.person.vatSdiCode"
                label="Codice destinatario *"
                :rules="sdiRules"
                outlined
                dense
                @blur="$refs.form.validate()"
              ></v-text-field>
            </v-col> -->
            </v-row>
          </template>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                type="button"
                large
                color="primary lighten-1"
                depressed
                class="mr-2 rounded-pill"
                @click.prevent="clear()"
              >
                {{ $t("paymentInvoice.goBack") }}
              </v-btn>
              <v-btn
                class="rounded-pill"
                type="button"
                large
                color="primary"
                depressed
                :disabled="disabledUpdate"
                @click.prevent="anagDataSave"
              >
                {{ $t("paymentInvoice.confirmAddressBtn") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </div>
  </div>
</template>
<style lang="scss">
.payment-invoice {
  .v-autocomplete {
    .v-input__icon {
      display: none;
    }
  }
  .v-icon {
    font-size: 18px !important;
  }
}
</style>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

import RegistrationService from "~/service/userService";
import CustomService from "@/service/customService";

import debounce from "lodash/debounce";
import { req } from "~/validator/commons.js";

import {
  requiredValue,
  isEmail,
  isCF,
  isCfIva
} from "~/validator/validationRules";

export default {
  name: "PaymentInvoice",
  props: {
    full: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      loading: false,
      valid: false,
      validPP: false,
      validFormEdit: false,
      lazy: false,
      dirty: false,
      address: null,
      customCountries: global.config.customCountries,
      billingData: {},
      fiscalPerson: null,
      fiscalPersons: [],
      addNewPersonFiscal: false,
      hasIdentifier: null,
      identifier: null,
      vatCode: null,
      personFiscalFound: false,
      requiredValue: requiredValue,
      cfRules: [requiredValue(), isCF()],
      emailPersonRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailRules: [
        (this.billingData && !this.billingData.person.emailCertified) ||
          isEmail()
      ],
      emailPecRules: [
        v => {
          if (v) {
            return (
              !req(v) ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
              "Digitare una e-mail valida"
            );
          } else if (this.billingData && !this.billingData.person.vatSdiCode) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      sdiRules: [
        v => {
          if (v) {
            return /^[A-Z0-9]{6,7}$/.test(v) || "Codice SDI non valido";
          } else if (
            this.billingData &&
            !this.billingData.person.emailCertified
          ) {
            return "PEC o SDI obbligatori";
          } else {
            return true;
          }
        }
      ],
      cfIvaRules: [requiredValue(), isCfIva()],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      needInvoice: "cart/needInvoice",
      warehouseId: "cart/getWarehouseId",
      serviceId: "cart/getSelectedService"
    }),
    disabledUpdate() {
      return !this.valid;
    }
  },
  methods: {
    async anagDataGet() {
      const res = await CustomService.anagDataGet(
        this.identifier,
        this.vatCode,
        this.billingData.person.personTypeId
      );

      if (res.response?.status === 0) {
        this.personFiscalFound = true;

        this.billingData.person.firstName = res.data.firstName;
        this.billingData.person.lastName = res.data.lastName;
        this.billingData.person.fiscalCode = res.data.fiscalCode;
        this.billingData.person.vatFiscalCode = res.data.vatFiscalCode;
        this.billingData.person.company = res.data.company;
        this.billingData.person.vatCode = res.data.vatCode;
        // this.billingData.billingAddress.name = "";
        this.billingData.billingAddress.address1 = res.data.address;
        this.billingData.billingAddress.addressName = res.data.addressName;
        this.billingData.billingAddress.province = res.data.province;
        this.billingData.billingAddress.city = res.data.city;
        this.billingData.billingAddress.postalcode = res.data.postalcode;
        this.billingData.billingAddress.addressNumber = res.data.addressNumber;

        this.billingData.person.personId = res.data.personId;
        this.billingData.person.gender = res.data.gender;
      }
    },
    async anagDataSave() {
      const payload = {
        personId: this.billingData.person.personId,
        personTypeId: this.billingData.person.personTypeId,
        codInt: this.identifier,
        firstName: this.billingData.person.firstName,
        lastName: this.billingData.person.lastName,
        gender: this.billingData.person.gender,
        fiscalCode: this.billingData.person.vatFiscalCode,
        address: this.billingData.billingAddress.address1,
        postalcode: this.billingData.billingAddress.postalcode,
        city: this.billingData.billingAddress.city,
        province: this.billingData.billingAddress.province,
        company:
          this.billingData.billingAddress.company ||
          `${this.billingData.person.firstName} ${this.billingData.person.lastName}`
      };
      const res = await CustomService.anagDataSave(payload);

      if (res.response?.status === 0) {
        await this.setCartInfo({ PERSON_FISCAL_ID: res.data.personFiscalId });
        this.clear();
        await this.getFiscalPersons();
        this.fiscalPerson = res.data.personFiscalId;
        this.validate();
      }
    },
    changed() {
      this.dirty = true;
    },
    clear() {
      this.addNewPersonFiscal = false;
      this.hasIdentifier = null;
      this.identifier = null;
      this.personFiscalFound = null;
    },
    addNewAddress() {
      this.addNewPersonFiscal = true;
      this.resetFormFields();
    },
    validate() {
      let _this = this;

      let isValid = true;

      if (
        _this.billingData.person.personTypeId == "2" ||
        _this.billingData.person.personTypeId == "4"
      ) {
        isValid = _this.$refs.vatFiscalCode.validate();
      }

      if (_this.needInvoice && !_this.fiscalPerson) {
        isValid = false;
      }

      _this.$emit("billingFormCompleted", isValid);
    },

    // async createPersonFiscal() {
    //   // if (this.$refs.form.validate()) {
    //   this.selectedCountry = this.customCountries.filter(country => {
    //     return (
    //       country.countryId == this.billingData.billingAddress.country.countryId
    //     );
    //   });
    //   this.billingData.billingAddress.country.countryId = this.selectedCountry[0].countryId;
    //   this.billingData.billingAddress.country.iso = this.selectedCountry[0].iso;
    //   this.billingData.billingAddress.country.name = this.selectedCountry[0].name;
    //   this.billingData.person.vatSdiCode = "0000000";
    //   const res = await RegistrationService.insertFiscalPerson(
    //     this.billingData
    //   );
    //   if (res.response?.status === 0 && res.data) {
    //     this.billingData.person = res.data;
    //     await this.getFiscalPersons();
    //     this.addNewPersonFiscal = !this.addNewPersonFiscal;
    //     this.fiscalPerson = res.data.personFiscalId;
    //     await this.setFiscalPerson();
    //     this.resetFormFields();
    //   }
    //   //   this.dirty = false;
    //   // }
    // },
    async setFiscalPerson() {
      this.personFiscalId = this.fiscalPerson;
      await this.setCartInfo({ PERSON_FISCAL_ID: this.fiscalPerson });
      this.validate();
    },

    resetFormFields() {
      this.billingData.person.firstName = "";
      this.billingData.person.lastName = "";
      this.billingData.person.vatFiscalCode = "";
      this.billingData.person.company = "";
      this.billingData.person.vatCode = "";
      this.billingData.billingAddress.name = "";
      this.billingData.billingAddress.address1 = "";
      this.billingData.billingAddress.addressName = "";
      this.billingData.billingAddress.province = "";
      this.billingData.billingAddress.city = "";
      this.billingData.billingAddress.postalcode = "";
      this.billingData.billingAddress.addressNumber = "";
    },

    async getUserDetail() {
      let _this = this;
      let res = await RegistrationService.getUserDetail();
      _this.billingData = res;
      this.billingData = res;
      this.resetFormFields();
    },
    async getFiscalPersons() {
      let res = await RegistrationService.getFiscalsPersonsList();
      if (res?.personFiscals) {
        this.fiscalPersons = [];
        for (const fp of res?.personFiscals) {
          if (fp.personTypeId === "1") {
            this.fiscalPersons.push({
              text: `${fp.firstName} ${fp.lastName}`,
              value: fp.personFiscalId
            });
          } else {
            this.fiscalPersons.push({
              text: `${fp.company} - ${fp?.addressName}`,
              value: fp.personFiscalId
            });
          }
        }
      }
    },
    async deletePersonFiscal() {
      if (this.fiscalPerson) {
        const res = await RegistrationService.deleteFiscalPerson(
          this.fiscalPerson
        );
        if (res) {
          await this.getFiscalPersons();
          await this.setCartInfo({ PERSON_FISCAL_ID: null });
          this.fiscalPerson = null;
          this.validate();
        }
      }
    },
    selectProvince(province) {
      let _this = this;
      if (province.Id) {
        _this.billingData.billingAddress.province = province.Sigla;
        _this.resetCity();
        _this.resetAddress();
      }
    },
    fetchCityList: debounce(function(val) {
      let _this = this;
      this.getCityList(val, _this.selectedProvince.Id);
    }, 400),
    selectCity(city) {
      let _this = this;
      if (city.Id != null) {
        _this.billingData.billingAddress.city = city.Nome;
        _this.resetAddress();
      } else if (this.cityList.length > 0) {
        _this.selectedCity = this.cityList[0];
        this.selectCity(_this.selectedCity);
      }
    },
    resetCity() {
      let _this = this;
      _this.selectedCity = null;
      _this.billingData.billingAddress.city = null;
      _this.cityList = [];
    },
    selectAddress(address) {
      let _this = this;
      if (address.Id) {
        this.billingData.billingAddress.address1 = address.Nome;
        this.postalcodeList = address.elencoCAP;
        if (address.elencoCAP.length == 1) {
          this.billingData.billingAddress.postalcode = address.elencoCAP[0];
        }
      } else if (this.addressList.length > 0) {
        _this.selectedAddress = this.addressList[0];
        this.selectAddress(this.addressList[0]);
      }
    },
    resetAddress() {
      let _this = this;
      _this.selectedAddress = null;
      _this.addressList = [];
      _this.billingData.billingAddress.address1 = null;
    },
    fetchAddressSuggestion: debounce(function(val) {
      this.getAddressList(val, this.selectedCity.Id);
    }, 400),

    async handleInvoiceChange() {
      let _this = this;
      await _this.setCartInfo({ need_invoice: !_this.needInvoice });
      await this.setCartInfo({ PERSON_FISCAL_ID: null });
      //   if (_this.needInvoice) {
      //     await _this.fetchDefaultData();
      //   }
      _this.validate();
    },
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    })
  },
  watch: {
    hasIdentifier(val, oldVal) {
      if (val != oldVal && val === false) {
        this.resetFormFields();
      }
    },
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    },
    // eslint-disable-next-line no-unused-vars
    fiscalPerson(val, oldVal) {
      if (val) {
        this.validate();
      }
    },
    "billingData.person.personTypeId": function(val) {
      if (val) {
        // this.$refs.form.validate();
        // this.$refs.vatFiscalCode.validate();

        const vatFiscalCodeField = this.$refs.vatFiscalCode;
        vatFiscalCodeField.$emit(
          "input",
          this.billingData.person.vatFiscalCode
        );

        this.validate();
      }
    }
  },
  async mounted() {
    await this.getUserDetail();
    await this.getFiscalPersons();
    // if (this.needInvoice) {
    this.validate();
    // }
    if (typeof this.sellCondition === "undefined") {
      this.sellCondition = "false";
    }
  }
};
</script>
